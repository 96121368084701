var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container pt-0 px-0 px-sm-3",
      attrs: { id: "cart-container" },
    },
    [
      _c(
        "div",
        {
          staticClass: "cart-header",
          staticStyle: {
            "border-top-left-radius": "4px",
            "border-top-right-radius": "4px",
          },
        },
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "div",
              [
                this.$store.state.autoEstimate.services.length > 0 &&
                !_vm.dataStorage.stepSettings.couponFree
                  ? _c(
                      "span",
                      {
                        staticClass: "text-right card-information",
                        on: {
                          click: function ($event) {
                            return _vm.displayPriceRangeDialog()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("EstimatedCost")) + " ")]
                    )
                  : _vm._e(),
                this.$store.state.autoEstimate.services.length > 0 &&
                !_vm.dataStorage.stepSettings.couponFree
                  ? _c(
                      "v-icon",
                      {
                        staticStyle: { "font-size": "18px" },
                        attrs: { color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.displayHelpDialog()
                          },
                        },
                      },
                      [_vm._v(" mdi-help-circle ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            this.dataStorage.isContactCenter &&
            _vm.contactCenterState.showClientDetailsWithId !== -1
              ? _c(
                  "div",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g({ attrs: { color: "white" } }, on),
                                    [_vm._v("mdi-account-outline")]
                                  ),
                                  _c(
                                    "span",
                                    _vm._g(
                                      { staticClass: "user-information" },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.contactCenterState.clientInfo
                                              .firstName
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4069637877
                        ),
                      },
                      [
                        _c("span", [
                          _c("table", [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { colspan: "2" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.contactCenterState.clientInfo
                                        .firstName +
                                        " " +
                                        _vm.contactCenterState.clientInfo
                                          .lastName
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Phone:  ")]),
                              _c("td", [_vm._v(_vm._s(this.phoneString()))]),
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Email: ")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.contactCenterState.clientInfo.email
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("p", { staticClass: "text-block mt-2" }, [
            _c("span", [_vm._v("$" + _vm._s(_vm.getTotalCosts().totalPrice))]),
            _c("span", { staticClass: "cents" }, [
              _vm._v(_vm._s(_vm.getTotalCosts().totalPriceCents) + " "),
            ]),
          ]),
          !_vm.dataStorage.stepSettings.couponFree
            ? _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "div",
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-right card-information",
                        on: {
                          click: function ($event) {
                            _vm.coupon.couponVisibility =
                              !_vm.coupon.couponVisibility
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Coupon")) + " ")]
                    ),
                    !_vm.coupon.couponVisibility
                      ? _c(
                          "v-icon",
                          {
                            attrs: { color: "white" },
                            on: {
                              click: function ($event) {
                                _vm.coupon.couponVisibility =
                                  !_vm.coupon.couponVisibility
                              },
                            },
                          },
                          [_vm._v(" mdi-chevron-down ")]
                        )
                      : _vm._e(),
                    _vm.coupon.couponVisibility
                      ? _c(
                          "v-icon",
                          {
                            attrs: { color: "white" },
                            on: {
                              click: function ($event) {
                                _vm.coupon.couponVisibility =
                                  !_vm.coupon.couponVisibility
                              },
                            },
                          },
                          [_vm._v("mdi-chevron-up ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", [
                  this.$store.state.autoEstimate.services.length > 0
                    ? _c(
                        "span",
                        {
                          staticClass: "text-right card-information",
                          on: {
                            click: function ($event) {
                              return _vm.displayPriceBreakDialog()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("PriceBreakDown")) + " ")]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm.coupon.couponVisibility &&
          !_vm.dataStorage.stepSettings.couponFree
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "mt-1", attrs: { id: "couponContainer" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-8 pl-0" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                elevation: 0,
                                placeholder: _vm.$t("EnterCouponCode"),
                                "background-color": "white",
                                "hide-details": "",
                                label: _vm.$t("CouponCode"),
                                solo: "",
                              },
                              model: {
                                value: _vm.dataStorage.stepSettings.couponCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataStorage.stepSettings,
                                    "couponCode",
                                    $$v
                                  )
                                },
                                expression:
                                  "dataStorage.stepSettings.couponCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "col-4 pl-0" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-none",
                                attrs: {
                                  height: "48",
                                  outlined: "",
                                  color: "white",
                                  loading: _vm.applyCouponLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.applyCouponDiscount()
                                  },
                                },
                              },
                              [_vm._v(" Apply ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "col-12 pl-0" },
                          [
                            _c(
                              "v-alert",
                              {
                                staticStyle: {
                                  "background-color": "#E4F2FD !important",
                                  color: "#2196F3",
                                },
                                attrs: { outlined: "", text: "" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("CouponValid")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "cart-content",
          staticStyle: {
            border: "thin solid rgba(0, 0, 0, 0.12)",
            "border-bottom-left-radius": "4px",
            "border-bottom-right-radius": "4px",
          },
        },
        [
          this.dataStorage.vehicle.isValid()
            ? _c("section", { attrs: { id: "vehicle" } }, [
                _c("div", { staticClass: "subheader-container" }, [
                  _c("h1", { staticClass: "subheader quote-header-4 mb-1" }, [
                    _vm._v(_vm._s(_vm.$t("Vehicle"))),
                  ]),
                ]),
                _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                  _c("span", { staticClass: "cart-gray" }, [
                    _vm._v(_vm._s(_vm.$t("Vehicle")) + ": "),
                  ]),
                  _c("span", { staticClass: "text--black" }, [
                    _vm._v(_vm._s(this.dataStorage.vehicle.toString()) + " "),
                  ]),
                ]),
                this.dataStorage.vehicle.vehicleType !== ""
                  ? _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                      _c("span", { staticClass: "cart-gray" }, [
                        _vm._v(_vm._s(_vm.$t("VehicleType")) + ": "),
                      ]),
                      _c("span", { staticClass: "text--black" }, [
                        _vm._v(
                          _vm._s(this.dataStorage.vehicle.vehicleType) + " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                this.dataStorage.vehicle.bodyType !== ""
                  ? _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                      _c("span", { staticClass: "cart-gray" }, [
                        _vm._v(_vm._s(_vm.$t("BodyType")) + ": "),
                      ]),
                      _c("span", { staticClass: "text--black" }, [
                        _vm._v(_vm._s(this.dataStorage.vehicle.bodyType) + " "),
                      ]),
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                  _c("span", { staticClass: "cart-gray" }, [
                    _vm._v(_vm._s(_vm.$t("Engine")) + ": "),
                  ]),
                  _c("span", { staticClass: "text--black" }, [
                    _vm._v(_vm._s(this.dataStorage.vehicle.engine) + " "),
                  ]),
                ]),
                _vm.dataStorage.vehicle.transmition != ""
                  ? _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                      _c("span", { staticClass: "cart-gray" }, [
                        _vm._v(_vm._s(_vm.$t("Transmition")) + ": "),
                      ]),
                      _c("span", { staticClass: "text--black" }, [
                        _vm._v(_vm._s(this.dataStorage.vehicle.transmition)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.dataStorage.vehicle.driveTrain != ""
                  ? _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                      _c("span", { staticClass: "cart-gray" }, [
                        _vm._v(_vm._s(_vm.$t("Drivetrain")) + ": "),
                      ]),
                      _c("span", { staticClass: "text--black" }, [
                        _vm._v(_vm._s(this.dataStorage.vehicle.driveTrain)),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          this.dataStorage.services.length > 0
            ? _c(
                "section",
                { attrs: { id: "service" } },
                [
                  _c("div", { staticClass: "subheader-container" }, [
                    _c("h1", { staticClass: "subheader quote-header-4 mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("Service"))),
                    ]),
                  ]),
                  _vm._l(
                    this.$store.state.autoEstimate.services,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "d-flex" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "cart-gray",
                              staticStyle: { width: "80%" },
                            },
                            [_vm._v(" " + _vm._s(item.service || "") + " ")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "cart-gray",
                              staticStyle: {
                                width: "110px",
                                "text-align": "right",
                                "padding-top": "0",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      item.estimateCosts.totalPromCost
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "align-self-baseline",
                              attrs: { color: "red" },
                              on: {
                                click: function ($event) {
                                  return _vm.delService(item)
                                },
                              },
                            },
                            [_vm._v(" mdi-delete-outline ")]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  this.dataStorage.stepSettings.couponCodeApplied
                    ? _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "span",
                          {
                            staticClass: "cart-gray red--text",
                            staticStyle: { width: "80%" },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Discount")) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "cart-gray red--text",
                            staticStyle: {
                              width: "110px",
                              "text-align": "right",
                              "padding-top": "0",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.getTotalCosts().discount
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          this.dataStorage.location.isValid()
            ? _c("section", { attrs: { id: "location" } }, [
                _c("div", { staticClass: "subheader-container " }, [
                  _c(
                    "h1",
                    {
                      staticClass: "subheader quote-header-4 mb-1 text--black",
                    },
                    [_vm._v(_vm._s(_vm.$t("Location")))]
                  ),
                ]),
                _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                  _c("span", { staticClass: "cart-gray" }, [
                    _vm._v(_vm._s(_vm.$t("Address")) + ": "),
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(this.dataStorage.location.address)),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.dataStorage.schedule.isValid()
            ? _c("section", { attrs: { id: "appointmentDateTime" } }, [
                _c(
                  "div",
                  { staticClass: "subheader-container margin-bottom-10" },
                  [
                    _c("h1", { staticClass: "subheader quote-header-4 mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("Schedule"))),
                    ]),
                  ]
                ),
                _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                  _c("span", { staticClass: "cart-gray" }, [
                    _vm._v(_vm._s(_vm.$t("DateTime")) + ": "),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.getAppointmentDateTime()))]),
                ]),
              ])
            : _vm._e(),
          _vm.dataStorage.payment.newPaymentProfile.CARD_NUMBER
            ? _c("section", { attrs: { id: "payment" } }, [
                _c(
                  "div",
                  { staticClass: "subheader-container margin-bottom-10" },
                  [
                    _c("h1", { staticClass: "subheader quote-header-4 mb-1" }, [
                      _vm._v(_vm._s(_vm.$t("Payment"))),
                    ]),
                  ]
                ),
                _vm.dataStorage.payment.isNewProfile
                  ? _c("div", [
                      _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                        _c("span", { staticClass: "cart-gray" }, [
                          _vm._v(_vm._s(_vm.$t("CardName")) + ": "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.dataStorage.payment.newPaymentProfile
                                .CARD_NAME
                            )
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                        _c("span", { staticClass: "cart-gray" }, [
                          _vm._v(
                            _vm._s(_vm.$t("CardNumberWithOutAsterix")) + ": "
                          ),
                        ]),
                        _c("span", { staticClass: "text--black" }, [
                          _vm._v(
                            _vm._s(
                              _vm.dataStorage.payment.newPaymentProfile
                                .CARD_NUMBER
                            )
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.dataStorage.payment.isNewProfile
                  ? _c("div", [
                      _c("p", { staticClass: "margin-bottom-0 mb-0" }, [
                        _c("span", { staticClass: "cart-gray" }, [
                          _vm._v(
                            _vm._s(_vm.$t("CardNumberWithOutAsterix")) + ": "
                          ),
                        ]),
                        _c("span", { staticClass: "text--black" }, [
                          _vm._v(
                            _vm._s(
                              _vm.dataStorage.payment.newPaymentProfile
                                .CARD_NUMBER
                            )
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          this.dataStorage.isContactCenter
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-2 mt-5 ml-1 text-none",
                  attrs: {
                    loading: _vm.savingEstimate,
                    elevation: "0",
                    color: "primary",
                    disabled: this.dataStorage.services.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      _vm.confirmDialog = true
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("SaveEstimate")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { id: "priceBreakDialog", width: "500" },
          model: {
            value: _vm.priceBreakDialog,
            callback: function ($$v) {
              _vm.priceBreakDialog = $$v
            },
            expression: "priceBreakDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      width: "100%",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("PriceBreakDown")))]),
                    _c(
                      "v-icon",
                      {
                        attrs: { color: "black" },
                        on: {
                          click: function ($event) {
                            return _vm.closePriceBreakDownDialog()
                          },
                        },
                      },
                      [_vm._v(" mdi-close ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "section",
                    { staticClass: "pt-4", attrs: { id: "quote" } },
                    _vm._l(_vm.dataStorage.services, function (item, index) {
                      return _c(
                        "div",
                        { key: index, attrs: { id: "services" } },
                        [
                          _c("p", { staticClass: "mb-0 black--text" }, [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v(_vm._s(item.service)),
                            ]),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { xs: "8" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "indent text-small color-gray",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Labor")))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "indent text-small color-gray",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("Part")))]
                                ),
                              ]),
                              _c("v-col", { attrs: { xs: "4" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-small color-gray text-right",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.estimateCosts.laborPromCost
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-small color-gray text-right",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.estimateCosts.partPromCost
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { xs: "8" } }, [
                        _c(
                          "p",
                          { staticClass: "black--text margin-bottom-0" },
                          [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v(_vm._s(_vm.$t("Totals"))),
                            ]),
                          ]
                        ),
                      ]),
                      _c("v-col", { attrs: { xs: "4" } }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "black--text margin-bottom-0  text-right",
                          },
                          [
                            _c("span", { staticClass: "text-bold" }, [
                              _vm._v(
                                _vm._s(_vm._f("currency")(_vm.getTotalPrice()))
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { attrs: { id: "job-detail" } },
                    [
                      _c(
                        "div",
                        { staticClass: "subheader-container margin-bottom-10" },
                        [
                          _c(
                            "h1",
                            { staticClass: "subheader quote-header-4" },
                            [_vm._v(_vm._s(_vm.$t("JobDetail")))]
                          ),
                          _c("div", { staticClass: "subheader-line" }),
                        ]
                      ),
                      _c("p", { staticClass: "mb-0 black--text" }, [
                        _c("span", { staticClass: "text-bold" }, [
                          _vm._v(_vm._s(_vm.$t("VehicleInformation"))),
                        ]),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { xs: "8" } }, [
                            _c(
                              "div",
                              { staticClass: "indent text-small color-gray" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Y")) +
                                    "/" +
                                    _vm._s(_vm.$t("M")) +
                                    "/" +
                                    _vm._s(_vm.$t("Mod")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-end",
                              attrs: { xs: "4" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "indent text-small color-gray" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dataStorage.vehicle.year) +
                                      " " +
                                      _vm._s(_vm.dataStorage.vehicle.make) +
                                      " " +
                                      _vm._s(_vm.dataStorage.vehicle.model) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { id: "priceRangeDialog", width: "500" },
          model: {
            value: _vm.priceRangeDialog,
            callback: function ($$v) {
              _vm.priceRangeDialog = $$v
            },
            expression: "priceRangeDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      width: "100%",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("EstimatedCost")))]),
                    _c(
                      "v-icon",
                      {
                        attrs: { color: "black" },
                        on: {
                          click: function ($event) {
                            return _vm.closePriceRangeDialog()
                          },
                        },
                      },
                      [_vm._v(" mdi-close ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c("v-simple-table", {
                    attrs: { "fixed-header": "", height: "300px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left w-50" }, [
                                  _vm._v(" " + _vm._s(_vm.$t("Service")) + " "),
                                ]),
                                _c("th", { staticClass: "text-right w-50" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("PriceRange")) +
                                      " (Min - Max) "
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.getServicesPriceRange(),
                                function (item, index) {
                                  return _c("tr", { key: index }, [
                                    _c("td", [_vm._v(_vm._s(item.service))]),
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(item.priceRange)),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { id: "helpDialog", width: "500" },
          model: {
            value: _vm.helpDialog,
            callback: function ($$v) {
              _vm.helpDialog = $$v
            },
            expression: "helpDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "div",
                  { staticClass: "d-flex w-100 justify-end" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "justify-end",
                        on: {
                          click: function ($event) {
                            _vm.helpDialog = !_vm.helpDialog
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-text",
                { staticClass: "p-0 m-0" },
                [
                  _c(
                    "v-alert",
                    { attrs: { type: "info", outlined: "", text: "" } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("EstimatedCostHelpInfo")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" " + _vm._s(_vm.$t("Confirm")) + " "),
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.$t("ConfirmSaveEstimate")) + " "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.confirmDialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        outlined: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveEstimate()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }